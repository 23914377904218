import { NextSeo } from 'next-seo'

import getHomeContentByUID from '@lib/prismic/fetch/documents'
import sliceResolvers from '@lib/prismic/helpers/sliceResolvers'

import AllSlices from '@components/prismic/AllSlices'

export default function HomePage({ homepage }: { homepage: any }) {
	return (
		<>
			<NextSeo title="Beauty Subscriptions and More" />
			<AllSlices body={homepage?.data?.body} />
		</>
	)
}

export async function getStaticProps({ previewData }: { previewData: any }) {
	// TODO: fix once rebrand in effect
	const homepage = await getHomeContentByUID('home_rebrand', previewData)

	if (!homepage) {
		return { notFound: true }
	}

	homepage.data.body = await sliceResolvers(homepage.data.body)

	return {
		props: {
			homepage
		}
	}
}
