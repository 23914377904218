import { Children, cloneElement, useMemo } from 'react'

import dynamic from 'next/dynamic'

// Global
const BrandsList = dynamic(() => import('./slices/BrandsList'))
const FAQ = dynamic(() => import('./slices/FAQ'))
const FancyProductsSlider = dynamic(() => import('./slices/FancyProductsSlider'))
const GalleryBox = dynamic(() => import('./slices/GalleryBox'))
const Heading = dynamic(() => import('./slices/Heading'))
const ImageCards = dynamic(() => import('./slices/ImageCards'))
const ListCards = dynamic(() => import('./slices/ListCards'))
const PricingCards = dynamic(() => import('./slices/PricingCards'))
const ProductGallery = dynamic(() => import('./slices/ProductGallery'))
const ProductsSlider = dynamic(() => import('./slices/ProductsSlider'))
const RichText = dynamic(() => import('./slices/RichText'))
const SignUpModal = dynamic(() => import('./slices/SignUpModal'))
const Split = dynamic(() => import('./slices/Split'))
const SplitWithBanner = dynamic(() => import('./slices/SplitWithBanner'))
const Split3 = dynamic(() => import('./slices/Split3'))
const SplitImageShadow = dynamic(() => import('./slices/SplitImageShadow'))
const ThreeWidgets = dynamic(() => import('./slices/ThreeWidgets'))

// Blog
const FullWidthText = dynamic(() => import('./slices/FullWidthText'))
const ImageAndText = dynamic(() => import('./slices/ImageAndText'))

export type SliceProps = {
	slice_type: string
	primary: {
		hero_theme?: {
			uid: string
		}
		section_theme?: {
			uid: string
		}
	}
}

const slicePicker = (slice: SliceProps, index: number) => {
	const sliceType = slice?.slice_type || {}

	switch (sliceType) {
		// Body Slices
		case 'brands_list':
			return <BrandsList slice={slice} />

		case 'faq':
			return <FAQ slice={slice} />

		case 'fancy_products_slider':
			return <FancyProductsSlider slice={slice} />

		case 'gallery_box':
			return <GalleryBox slice={slice} index={index} />

		case 'heading':
			return <Heading slice={slice} />

		case 'image_cards':
			return <ImageCards slice={slice} />

		case 'list_cards':
			return <ListCards slice={slice} />

		case 'pricing_cards':
			return <PricingCards slice={slice} />

		case 'product_gallery':
			return <ProductGallery slice={slice} />

		case 'products_slider':
			return <ProductsSlider slice={slice} />

		case 'rich_text':
			return <RichText slice={slice} />

		case 'sign_up_modal':
			return <SignUpModal slice={slice} />

		case 'split':
			return <Split slice={slice} />

		case 'split_with_banner':
			return <SplitWithBanner slice={slice} />

		case 'split_3':
			return <Split3 slice={slice} sliceIndex={index} />

		case 'split_image_shadow':
			return <SplitImageShadow slice={slice} />

		case 'three_widgets':
			return <ThreeWidgets slice={slice} />

		// Blog Slices
		// TODO: Consider refactoring so these aren't mixed with the Global slices
		case 'full_width_text':
			return <FullWidthText slice={slice} />

		case 'image_and_text':
			return <ImageAndText slice={slice} />

		default:
			console.info(
				sliceType ? `Missing component for slice of type: ${sliceType}` : 'No slice defined!'
			)
			return null
	}
}

interface Props {
	slice: SliceProps
	index: number
}

export default function Slice({ slice, index }: Props) {
	const foundSlice = useMemo(() => slicePicker(slice, index), [slice, index])
	return foundSlice ? cloneElement(Children.only(foundSlice), { slice, index }, null) : null
}
