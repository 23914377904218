import Slice from './Slice'

export default function AllSlices({ body }: any) {
	return (
		<>
			{/* Hero Slices */}
			{/* {Array.isArray(hero) ? (
				<>
					{hero.map((slice, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<Slice slice={slice} key={`${slice?.sliceType}-${i}`} />
					))}
				</>
			) : null} */}

			{/* Body Slices */}
			{Array.isArray(body) ? (
				<>
					{body.map((slice, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<Slice slice={slice} index={i} key={`${slice?.slice_type}-${i}`} />
					))}
				</>
			) : null}
		</>
	)
}
